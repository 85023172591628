<template>
  <div ref="modalArea" :class="{'filter filter_popup': true, '_opened': filterOpen}">
    <div class="filter__wrapper">
      <div ref="modalInner" class="filter__form">
        <div class="filter-select">
          <div class="filter-select__label">
            {{ $t('filter.platform') }}
          </div>
          <div v-if="!loadingData" :class="{'filter-select__select select': true, 'is-active': selectActive[0]}">
            <div class="select__header" @click="activateSelect(0)">
              <span class="select__current">
                {{ changeFilter.platform.length > 0 ? $ucfirst(changeFilter.platform) : $t('filter.all_platforms') }}
              </span>
              <div class="select__icon">
                <i class="bx bxs-down-arrow down-icon"></i>
              </div>
            </div>
            <div class="select__body">
              <div v-for="(platform, ind) in platforms" :key="ind" class="select__item" @click="changePlatform(platform.platform.toLowerCase())">
                {{ platform.platform }}
              </div>
            </div>
          </div>
          <div v-else class="sort-field m-field m-field-select">
            <Skeleton width="100%" height="35px" />
          </div>
        </div>
        <div class="filter-select">
          <div class="filter-select__label">
            {{ $t('filter.algorithm') }}
          </div>
          <div :class="{'filter-select__select select': true, 'is-active': selectActive[1]}">
            <div class="select__header" @click="activateSelect(1)">
              <span class="select__current">
                {{ currentAlgorithm !== undefined ? currentAlgorithm.label : '...' }}
              </span>
              <div class="select__icon">
                <i class="bx bxs-down-arrow down-icon"></i>
              </div>
            </div>
            <div class="select__body">
              <div v-for="(algo, ind) in algorithms" :key="ind" class="select__item" @click="changeAlgo(algo.value)">
                {{ algo.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="filter-range">
          <div class="filter-range__title">
            {{ $t('filter.market_cap') }}
          </div>
          <div class="filter-range__inputs">
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.marketCapMin"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.from')"
                @input="changeMarketCapMin"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.marketCapMax"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.to')"
                @input="changeMarketCapMax"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
          </div>
          <div v-if="errors.marketCap.length > 0" class="error-hint">
            {{ errors.marketCap }}
          </div>
          <div class="pips">
            <div
              v-for="(pip, ind) in marketCapPips"
              :key="ind"
              :class="{'pip': true, 'passed': pip.value < changeFilter.marketCapPip, 'active': pip.value === changeFilter.marketCapPip}"
              @click="setMarketCap(pip.value)"
            >
              <div class="circle"></div>
              <p class="label">
                {{ pip.label }}
              </p>
            </div>
          </div>
        </div>
        <div class="filter-range">
          <div class="filter-range__title">
            {{ $t('filter.price') }}
          </div>
          <div class="filter-range__inputs">
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.priceMin"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.from')"
                @input="changePriceMin"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.priceMax"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.to')"
                @input="changePriceMax"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
          </div>
          <div v-if="errors.price.length > 0" class="error-hint">
            {{ errors.price }}
          </div>
          <div class="pips">
            <div
              v-for="(pip, ind) in pricePips"
              :key="ind"
              :class="{'pip': true, 'passed': pip.value < changeFilter.pricePip, 'active': pip.value === changeFilter.pricePip}"
              @click="setPrice(pip.value)"
            >
              <div class="circle"></div>
              <p class="label">
                {{ pip.label }}
              </p>
            </div>
          </div>
        </div>
        <div class="filter-range">
          <div class="filter-range__title">
            {{ $t('filter.trading_volume') }}
          </div>
          <div class="filter-range__inputs">
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.volumeMin"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.from')"
                @input="changeVolumeMin"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.volumeMax"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.to')"
                @input="changeVolumeMax"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
          </div>
          <div v-if="errors.volume.length > 0" class="error-hint">
            {{ errors.volume }}
          </div>
          <div class="pips">
            <div
              v-for="(pip, ind) in volumePips"
              :key="ind"
              :class="{'pip': true, 'passed': pip.value < changeFilter.volumePip, 'active': pip.value === changeFilter.volumePip}"
              @click="setVolume(pip.value)"
            >
              <div class="circle"></div>
              <p class="label">
                {{ pip.label }}
              </p>
            </div>
          </div>
        </div>
        <div class="filter-range">
          <div class="filter-range__title">
            {{ $t('filter.supply') }}
          </div>
          <div class="filter-range__inputs">
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.supplyMin"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.from')"
                @input="changeSupplyMin"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
            <div class="filter-range__input-box">
              <input
                v-model="changeFilter.supplyMax"
                type="number"
                class="filter-range__input"
                :placeholder="$t('filter.to')"
                @input="changeSupplyMax"
              >
              <div class="filter-range__icon">
                $
              </div>
            </div>
          </div>
          <div v-if="errors.supply.length > 0" class="error-hint">
            {{ errors.supply }}
          </div>
          <div class="pips">
            <div
              v-for="(pip, ind) in supplyPips"
              :key="ind"
              :class="{'pip': true, 'passed': pip.value < changeFilter.supplyPip, 'active': pip.value === changeFilter.supplyPip}"
              @click="setSupply(pip.value)"
            >
              <div class="circle"></div>
              <p class="label">
                {{ pip.label }}
              </p>
            </div>
          </div>
        </div>

        <div class="filter-search">
          <div class="filter-search__icon">
            <i class="bx bx-search"></i>
          </div>
          <input v-model="changeFilter.search" type="text" class="filter-search__input" :placeholder="$t('search.search')">
        </div>
        <div class="filter__buttons">
          <button type="button" class="filter__button filter__button_stroke" @click="closeModal">
            {{ $t('common.cancel') }}
          </button>
          <button type="button" class="filter__button filter__button_filled danger" @click="resetFilter">
            {{ $t('filter.reset') }}
          </button>
          <button type="button" class="filter__button filter__button_filled" :disabled="hasErrors" @click="updateFilter">
            {{ $t('common.apply') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Skeleton } from 'vue-loading-skeleton'

export default {
  name: 'ProjectsFilterModal',
  components: {
    Skeleton
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {
          page: 1,
          step: 50,
          algorithm: '',
          category: '',
          order: 'ASC',
          orderField: 'cmc_rank',
          platform: '',
          search: '',
          type: 'all',
          marketCapPip: null,
          marketCapMin: null,
          marketCapMax: null,
          pricePip: null,
          priceMin: null,
          priceMax: null,
          supplyPip: null,
          supplyMin: null,
          supplyMax: null,
          volumePip: null,
          volumeMin: null,
          volumeMax: null
        }
      }
    }
  },
  data() {
    const filterCopy = JSON.parse(JSON.stringify(this.filter))
    return {
      loadingData: false,
      changeFilter: filterCopy,
      selectActive: [false, false],
      algorithms: [
        {
          label: this.$t('filter.all_algorithms'),
          value: ''
        },
        {
          label: this.$t('projects.poa'),
          value: 'poa'
        },
        {
          label: this.$t('projects.pow'),
          value: 'pow'
        },
        {
          label: this.$t('projects.pos'),
          value: 'pos'
        },
        {
          label: this.$t('projects.dpos'),
          value: 'dpos'
        },
        {
          label: this.$t('projects.poet'),
          value: 'poet'
        }
      ],
      supplyPips: [
        {
          label: '>1' + this.$t('common.M'),
          value: 1000000
        },
        {
          label: '10' + this.$t('common.M'),
          value: 10000000
        },
        {
          label: '100' + this.$t('common.M'),
          value: 100000000
        },
        {
          label: '1' + this.$t('common.B'),
          value: 1000000000
        },
        {
          label: '10' + this.$t('common.B'),
          value: 10000000000
        },
        {
          label: '100' + this.$t('common.B'),
          value: 100000000000
        },
        {
          label: '1' + this.$t('common.T'),
          value: 1000000000000
        },
        {
          label: '5' + this.$t('common.T'),
          value: 5000000000000
        }
      ],
      volumePips: [
        {
          label: '>1' + this.$t('common.M'),
          value: 1000000
        },
        {
          label: '10' + this.$t('common.M'),
          value: 10000000
        },
        {
          label: '100' + this.$t('common.M'),
          value: 100000000
        },
        {
          label: '500' + this.$t('common.M'),
          value: 500000000
        },
        {
          label: '1' + this.$t('common.B'),
          value: 1000000000
        },
        {
          label: '10' + this.$t('common.B'),
          value: 10000000000
        },
        {
          label: '100' + this.$t('common.B'),
          value: 100000000000
        },
        {
          label: '1' + this.$t('common.T'),
          value: 1000000000000
        }
      ],
      pricePips: [
        {
          label: '>1',
          value: 1
        },
        {
          label: '10',
          value: 10
        },
        {
          label: '100',
          value: 100
        },
        {
          label: '1' + this.$t('common.k'),
          value: 1000
        },
        {
          label: '10' + this.$t('common.k'),
          value: 10000
        },
        {
          label: '100' + this.$t('common.k'),
          value: 100000
        },
        {
          label: '500' + this.$t('common.k'),
          value: 500000
        }
      ],
      marketCapPips: [
        {
          label: '>1' + this.$t('common.M'),
          value: 1000000
        },
        {
          label: '10' + this.$t('common.M'),
          value: 10000000
        },
        {
          label: '100' + this.$t('common.M'),
          value: 100000000
        },
        {
          label: '1' + this.$t('common.B'),
          value: 1000000000
        },
        {
          label: '10' + this.$t('common.B'),
          value: 10000000000
        },
        {
          label: '100' + this.$t('common.B'),
          value: 100000000000
        },
        {
          label: '1' + this.$t('common.T'),
          value: 1000000000000
        },
        {
          label: '>12' + this.$t('common.T'),
          value: 12000000000000
        }
      ],
      errors: {
        supply: '',
        marketCap: '',
        volume: '',
        price: ''
      }
    }
  },
  computed: {
    ...mapGetters('modal', [
      'filterOpen'
    ]),
    ...mapGetters('projects', [
      'platforms'
    ]),
    currentAlgorithm() {
      return this.algorithms.find(itm => itm.value === this.changeFilter.algorithm)
    },
    hasErrors() {
      return this.errors.marketCap.length > 0 ||
      this.errors.supply.length > 0 ||
      this.errors.price.length > 0 ||
      this.errors.volume.length > 0
    }
  },
  mounted() {
    this.loadFilterData()
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeFilterState'
    ]),
    ...mapActions('projects', [
      'fetchPlatforms'
    ]),
    updateFilter() {
      const price = this.changeFilter.priceMin !== null ? this.changeFilter.priceMin + '-' + this.changeFilter.priceMax : ''
      const marketCap = this.changeFilter.marketCapMin !== null ? this.changeFilter.marketCapMin + '-' + this.changeFilter.marketCapMax : ''
      const volume = this.changeFilter.volumeMin !== null ? this.changeFilter.volumeMin + '-' + this.changeFilter.volumeMax : ''
      const supply = this.changeFilter.supplyMin !== null ? this.changeFilter.supplyMin + '-' + this.changeFilter.supplyMax : ''
      this.$emit('change', {
        ...this.changeFilter,
        price,
        marketCap,
        volume,
        supply
      })
      this.closeModal()
    },
    handleClickEvents(evt) {
      const keycode = (evt.keyCode ? evt.keyCode : evt.which)
      if (keycode === 27 && this.active === true) {
        evt.preventDefault()
        this.closeModal()
      }
      if (
        !evt.target.closest('.filter-select__select') &&
          !evt.target.closest('.select__body') &&
          !evt.target.closest('.select__current') &&
          !evt.target.closest('.select__header')
      ) {
        this.closeDropdowns()
      }
    },
    closeDropdowns() {
      for (let ind = 0; ind < this.selectActive.length; ++ind) {
        this.$set(this.selectActive, ind, false)
      }
    },
    changeAlgo(newAlgo) {
      if (this.changeFilter.algorithm !== newAlgo) {
        this.changeFilter.algorithm = newAlgo
      }
    },
    changePlatform(newPlatform) {
      if (this.changeFilter.platform !== newPlatform) {
        this.changeFilter.platform = newPlatform
      }
    },
    setSupply(newSupply) {
      if (this.changeFilter.supplyPip !== newSupply) {
        this.changeFilter.supplyPip = newSupply
        this.changeFilter.supplyMin = 0
        this.changeFilter.supplyMax = newSupply
      }
    },
    changeSupplyMin() {
      const numericValue = parseFloat(this.changeFilter.supplyMin)
      this.changeFilter.supplyMin = numericValue
      this.errors.supply = ''
      if (numericValue < 0) {
        this.changeFilter.supplyMin = 0
      }
      if (numericValue >= this.changeFilter.supplyMax) {
        this.errors.supply = this.$t('filter.range_error')
        this.changePip('supply', numericValue)
      } else {
        this.changePip('supply', this.changeFilter.supplyMax)
      }
    },
    changeSupplyMax() {
      const numericValue = parseFloat(this.changeFilter.supplyMax)
      this.changeFilter.supplyMax = numericValue
      this.errors.supply = ''
      if (numericValue < 0) {
        this.changeFilter.supplyMax = 0
      }
      if (numericValue < this.changeFilter.supplyMin) {
        this.errors.supply = this.$t('filter.range_error')
        this.changePip('supply', this.changeFilter.supplyMin)
      } else {
        this.changePip('supply', numericValue)
      }
    },
    setVolume(newVolume) {
      if (this.changeFilter.volumePip !== newVolume) {
        this.changeFilter.volumePip = newVolume
        this.changeFilter.volumeMin = 0
        this.changeFilter.volumeMax = newVolume
      }
    },
    changeVolumeMin() {
      const numericValue = parseFloat(this.changeFilter.volumeMin)
      this.changeFilter.volumeMin = numericValue
      this.errors.volume = ''
      if (numericValue < 0) {
        this.changeFilter.volumeMin = 0
      }
      if (numericValue >= this.changeFilter.volumeMax) {
        this.errors.volume = this.$t('filter.range_error')
        this.changePip('volume', numericValue)
      } else {
        this.changePip('volume', this.changeFilter.volumeMax)
      }
    },
    changeVolumeMax() {
      const numericValue = parseFloat(this.changeFilter.volumeMax)
      this.changeFilter.volumeMax = numericValue
      this.errors.volume = ''
      if (numericValue < 0) {
        this.changeFilter.volumeMax = 0
      }
      if (numericValue < this.changeFilter.volumeMin) {
        this.errors.volume = this.$t('filter.range_error')
        this.changePip('volume', this.changeFilter.volumeMin)
      } else {
        this.changePip('volume', numericValue)
      }
    },
    setPrice(newPrice) {
      if (this.changeFilter.pricePip !== newPrice) {
        this.changeFilter.pricePip = newPrice
        this.changeFilter.priceMin = 0
        this.changeFilter.priceMax = newPrice
      }
    },
    changePriceMin() {
      const numericValue = parseFloat(this.changeFilter.priceMin)
      this.changeFilter.priceMin = numericValue
      this.errors.price = ''
      if (numericValue < 0) {
        this.changeFilter.priceMin = 0
      }
      if (numericValue >= this.changeFilter.priceMax) {
        this.errors.price = this.$t('filter.range_error')
        this.changePip('price', numericValue)
      } else {
        this.changePip('price', this.changeFilter.priceMax)
      }
    },
    changePriceMax() {
      const numericValue = parseFloat(this.changeFilter.priceMax)
      this.changeFilter.priceMax = numericValue
      this.errors.price = ''
      if (numericValue < 0) {
        this.changeFilter.priceMax = 0
      }
      if (numericValue < this.changeFilter.priceMin) {
        this.errors.price = this.$t('filter.range_error')
        this.changePip('price', this.changeFilter.priceMin)
      } else {
        this.changePip('price', numericValue)
      }
    },
    setMarketCap(newMarketCap) {
      if (this.changeFilter.marketCapPip !== newMarketCap) {
        this.changeFilter.marketCapPip = newMarketCap
        this.changeFilter.marketCapMin = 0
        this.changeFilter.marketCapMax = newMarketCap
      }
    },
    changeMarketCapMin() {
      const numericValue = parseFloat(this.changeFilter.marketCapMin)
      this.changeFilter.marketCapMin = numericValue
      this.errors.marketCap = ''
      if (numericValue < 0) {
        this.changeFilter.marketCapMin = 0
      }
      if (numericValue >= this.changeFilter.marketCapMax) {
        this.errors.marketCap = this.$t('filter.range_error')
        this.changePip('marketCap', numericValue)
      } else {
        this.changePip('marketCap', this.changeFilter.marketCapMax)
      }
    },
    changeMarketCapMax() {
      const numericValue = parseFloat(this.changeFilter.marketCapMax)
      this.changeFilter.marketCapMax = numericValue
      this.errors.marketCap = ''
      if (numericValue < 0) {
        this.changeFilter.marketCapMax = 0
      }
      if (numericValue < this.changeFilter.marketCapMin) {
        this.errors.price = this.$t('filter.range_error')
        this.changePip('marketCap', this.changeFilter.marketCapMin)
      } else {
        this.changePip('marketCap', numericValue)
      }
    },
    changePip(type, maxValue) {
      const pipValue = parseFloat(maxValue)
      const currentIndex = this[type + 'Pips'].findIndex(itm => itm.value >= pipValue)
      if (currentIndex !== -1) {
        this.changeFilter[type + 'Pip'] = this[type + 'Pips'][currentIndex].value
      } else if (pipValue < this[type + 'Pips'][0].value) {
        this.changeFilter[type + 'Pip'] = this[type + 'Pips'][0].value
      } else {
        this.changeFilter[type + 'Pip'] = this[type + 'Pips'][this[type + 'Pips'].length - 1].value
      }
    },
    loadFilterData() {
      this.loadingData = true
      this.fetchPlatforms()
        .then(() => {
          this.loadingData = false
        })
        .catch((err) => {
          console.error(err)
          this.loadingData = false
        })
    },
    resetFilter() {
      this.changeFilter = {
        page: 1,
        step: 50,
        algorithm: '',
        category: '',
        marketCap: '',
        order: 'ASC',
        orderField: 'cmc_rank',
        platform: '',
        price: '',
        search: '',
        supply: '',
        type: 'all',
        volume: '',
        marketCapPip: null,
        marketCapMin: 0,
        marketCapMax: 12000000000000,
        pricePip: null,
        priceMin: 0,
        priceMax: 1000000,
        supplyPip: null,
        supplyMin: 0,
        supplyMax: 1200000000000,
        volumePip: null,
        volumeMin: 0,
        volumeMax: 99000000000
      }
      this.updateFilter()
    },
    activateSelect(selectIndex) {
      this.closeDropdowns()
      this.$set(this.selectActive, selectIndex, !this.selectActive[selectIndex])
    },
    closeModal() {
      this.changeFilterState({
        state: false
      })
      this.closeDropdowns()
      document.querySelector('body').classList.remove('scroll-none')
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/components/modal.css');
  @import url('~/assets/css/components/filter.css');
</style>
