var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "modalArea",
      class: { "filter filter_popup": true, _opened: _vm.filterOpen },
    },
    [
      _c("div", { staticClass: "filter__wrapper" }, [
        _c("div", { ref: "modalInner", staticClass: "filter__form" }, [
          _c("div", { staticClass: "filter-select" }, [
            _c("div", { staticClass: "filter-select__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("filter.platform")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            !_vm.loadingData
              ? _c(
                  "div",
                  {
                    class: {
                      "filter-select__select select": true,
                      "is-active": _vm.selectActive[0],
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "select__header",
                        on: {
                          click: function ($event) {
                            return _vm.activateSelect(0)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "select__current" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.changeFilter.platform.length > 0
                                  ? _vm.$ucfirst(_vm.changeFilter.platform)
                                  : _vm.$t("filter.all_platforms")
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "select__body" },
                      _vm._l(_vm.platforms, function (platform, ind) {
                        return _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "select__item",
                            on: {
                              click: function ($event) {
                                _vm.changePlatform(
                                  platform.platform.toLowerCase()
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(platform.platform) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "sort-field m-field m-field-select" },
                  [
                    _c("Skeleton", {
                      attrs: { width: "100%", height: "35px" },
                    }),
                  ],
                  1
                ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-select" }, [
            _c("div", { staticClass: "filter-select__label" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("filter.algorithm")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                class: {
                  "filter-select__select select": true,
                  "is-active": _vm.selectActive[1],
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "select__header",
                    on: {
                      click: function ($event) {
                        return _vm.activateSelect(1)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "select__current" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.currentAlgorithm !== undefined
                              ? _vm.currentAlgorithm.label
                              : "..."
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "select__body" },
                  _vm._l(_vm.algorithms, function (algo, ind) {
                    return _c(
                      "div",
                      {
                        key: ind,
                        staticClass: "select__item",
                        on: {
                          click: function ($event) {
                            return _vm.changeAlgo(algo.value)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(algo.label) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-range" }, [
            _c("div", { staticClass: "filter-range__title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("filter.market_cap")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-range__inputs" }, [
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.marketCapMin,
                      expression: "changeFilter.marketCapMin",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.from") },
                  domProps: { value: _vm.changeFilter.marketCapMin },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "marketCapMin",
                          $event.target.value
                        )
                      },
                      _vm.changeMarketCapMin,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.marketCapMax,
                      expression: "changeFilter.marketCapMax",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.to") },
                  domProps: { value: _vm.changeFilter.marketCapMax },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "marketCapMax",
                          $event.target.value
                        )
                      },
                      _vm.changeMarketCapMax,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.marketCap.length > 0
              ? _c("div", { staticClass: "error-hint" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.errors.marketCap) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pips" },
              _vm._l(_vm.marketCapPips, function (pip, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    class: {
                      pip: true,
                      passed: pip.value < _vm.changeFilter.marketCapPip,
                      active: pip.value === _vm.changeFilter.marketCapPip,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setMarketCap(pip.value)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "circle" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(pip.label) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-range" }, [
            _c("div", { staticClass: "filter-range__title" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("filter.price")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-range__inputs" }, [
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.priceMin,
                      expression: "changeFilter.priceMin",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.from") },
                  domProps: { value: _vm.changeFilter.priceMin },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "priceMin",
                          $event.target.value
                        )
                      },
                      _vm.changePriceMin,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.priceMax,
                      expression: "changeFilter.priceMax",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.to") },
                  domProps: { value: _vm.changeFilter.priceMax },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "priceMax",
                          $event.target.value
                        )
                      },
                      _vm.changePriceMax,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.price.length > 0
              ? _c("div", { staticClass: "error-hint" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.errors.price) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pips" },
              _vm._l(_vm.pricePips, function (pip, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    class: {
                      pip: true,
                      passed: pip.value < _vm.changeFilter.pricePip,
                      active: pip.value === _vm.changeFilter.pricePip,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setPrice(pip.value)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "circle" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(pip.label) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-range" }, [
            _c("div", { staticClass: "filter-range__title" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$t("filter.trading_volume")) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-range__inputs" }, [
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.volumeMin,
                      expression: "changeFilter.volumeMin",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.from") },
                  domProps: { value: _vm.changeFilter.volumeMin },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "volumeMin",
                          $event.target.value
                        )
                      },
                      _vm.changeVolumeMin,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.volumeMax,
                      expression: "changeFilter.volumeMax",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.to") },
                  domProps: { value: _vm.changeFilter.volumeMax },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "volumeMax",
                          $event.target.value
                        )
                      },
                      _vm.changeVolumeMax,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.volume.length > 0
              ? _c("div", { staticClass: "error-hint" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.errors.volume) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pips" },
              _vm._l(_vm.volumePips, function (pip, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    class: {
                      pip: true,
                      passed: pip.value < _vm.changeFilter.volumePip,
                      active: pip.value === _vm.changeFilter.volumePip,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setVolume(pip.value)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "circle" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(pip.label) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-range" }, [
            _c("div", { staticClass: "filter-range__title" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.$t("filter.supply")) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "filter-range__inputs" }, [
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.supplyMin,
                      expression: "changeFilter.supplyMin",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.from") },
                  domProps: { value: _vm.changeFilter.supplyMin },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "supplyMin",
                          $event.target.value
                        )
                      },
                      _vm.changeSupplyMin,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "filter-range__input-box" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.changeFilter.supplyMax,
                      expression: "changeFilter.supplyMax",
                    },
                  ],
                  staticClass: "filter-range__input",
                  attrs: { type: "number", placeholder: _vm.$t("filter.to") },
                  domProps: { value: _vm.changeFilter.supplyMax },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.changeFilter,
                          "supplyMax",
                          $event.target.value
                        )
                      },
                      _vm.changeSupplyMax,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "filter-range__icon" }, [
                  _vm._v("\n              $\n            "),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.errors.supply.length > 0
              ? _c("div", { staticClass: "error-hint" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.errors.supply) + "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pips" },
              _vm._l(_vm.supplyPips, function (pip, ind) {
                return _c(
                  "div",
                  {
                    key: ind,
                    class: {
                      pip: true,
                      passed: pip.value < _vm.changeFilter.supplyPip,
                      active: pip.value === _vm.changeFilter.supplyPip,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setSupply(pip.value)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "circle" }),
                    _vm._v(" "),
                    _c("p", { staticClass: "label" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(pip.label) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-search" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.changeFilter.search,
                  expression: "changeFilter.search",
                },
              ],
              staticClass: "filter-search__input",
              attrs: { type: "text", placeholder: _vm.$t("search.search") },
              domProps: { value: _vm.changeFilter.search },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.changeFilter, "search", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter__buttons" }, [
            _c(
              "button",
              {
                staticClass: "filter__button filter__button_stroke",
                attrs: { type: "button" },
                on: { click: _vm.closeModal },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.cancel")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "filter__button filter__button_filled danger",
                attrs: { type: "button" },
                on: { click: _vm.resetFilter },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("filter.reset")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "filter__button filter__button_filled",
                attrs: { type: "button", disabled: _vm.hasErrors },
                on: { click: _vm.updateFilter },
              },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("common.apply")) + "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select__icon" }, [
      _c("i", { staticClass: "bx bxs-down-arrow down-icon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select__icon" }, [
      _c("i", { staticClass: "bx bxs-down-arrow down-icon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "filter-search__icon" }, [
      _c("i", { staticClass: "bx bx-search" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }